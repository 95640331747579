import { NavLink, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { openModal } from "../../data/slices/modals";
import i18next from "i18next";
import cookies from "js-cookie";
import Logo from "../Logo/Logo";
import fb from "../../assets/icons/fb1.svg";
import insta from "../../assets/icons/insta.svg";
import tw from "../../assets/icons/tw.svg";
import copyRight from "../../assets/icons/Vector.svg";
import { changeTab } from "../../data/slices/tabsSlice";
import { handleStateChange } from "../../data/slices/filtersSlice";

function Footer() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentLanguageCode = cookies.get("i18next") || "en";
  return (
    <>
      <div
        className={`footer ${currentLanguageCode === "ar" ? "footer_ar" : ""}`}
      >
        <div className="left">
          <Logo />
          <p className="content">
            {t("footer.text1")}
            <br />
            {t("footer.text2")}
            <br />
            {t("footer.text22")}
          </p>
        </div>
        <div className="s1">
          <NavLink className="nav_footer" to="/">
            {t("header.home")}
          </NavLink>
          <div
            className="items_s1"
            style={{
              flexDirection:
                currentLanguageCode === "ar" ? "row-reverse" : "row",
              justifyContent:
                currentLanguageCode === "ar" ? "flex-end" : "flex-start",
            }}
          >
            <span>
              <NavLink className="contact_sub nav_s1" to={`/?service=2`}>
                {t("services-list.service2")}
              </NavLink>
              <NavLink className="contact_sub nav_s1" to={`/?service=4`}>
                {t("services-list.service4")}
              </NavLink>
            </span>
            <span>
              <NavLink className="contact_sub nav_s1" to={`/?service=1`}>
                {t("services-list.service1")}
              </NavLink>
              <NavLink className="contact_sub nav_s1" to={`/?service=3`}>
                {t("services-list.service3")}
              </NavLink>
            </span>
          </div>
        </div>

        <div className="s2">
          {currentLanguageCode === "ar" ? (
            <NavLink className="nav_footer" to="/about-us">
              {t("header.about-us")}
            </NavLink>
          ) : (
            <NavLink className="nav_footer" to="/">
              {t("header.offers")}
            </NavLink>
          )}
        </div>
        <div className="s3">
          {currentLanguageCode === "ar" ? (
            <NavLink className="nav_footer" to="/">
              {t("header.offers")}
            </NavLink>
          ) : (
            <NavLink className="nav_footer" to="/about-us">
              {t("header.about-us")}
            </NavLink>
          )}
        </div>
        <div className="right">
          <NavLink className="nav_footer">{t("header.contact_as")}</NavLink>
          <a
            target="_blank"
            href={`https://${t("footer.contact_web")}`}
            className="contact_sub contact_web text-decoration-none"
          >
            <span>{t("footer.contact_web_title")}</span>
            {t("footer.contact_web")}
          </a>
          <a
            href={`mailto:${t("footer.contact_email")}`}
            className="contact_sub contact_mail text-decoration-none"
          >
            <span>{t("footer.contact_email_title")}</span>
            {t("footer.contact_email")}
          </a>
          <a
            href={`tel:+966920025229`}
            className="contact_sub contact_phone text-decoration-none"
          >
            <span>{t("footer.contact_phone_title")}</span>
            {t("footer.contact_phone")}
          </a>
          <div className="icons">
            <a href="">
              <img src={fb} alt="" />
            </a>
            <a href="">
              <img src={tw} alt="" />
            </a>
            <a href="">
              <img src={insta} alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className="bottom_section">
        <img src={copyRight} alt="" />
        {t("footer.text3")}
      </div>
    </>
  );
}

export default Footer;
